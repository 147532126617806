<script>
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    group: {
      type: Object,
      default: null,
    },
    collections: {
      type: Array,
      default: null,
    },
    collection: {
      type: Object,
      default: null,
    },
  },
  data() {
    const newGroup = {
      name: '',
      description: '',
      collection: this.collection?.uuid,
      type: 'CARD',
      state: 'DRAFT',
      global: this.getSharedStoreGetter('hasProPlan'),
      automaticAdd: this.getSharedStoreGetter('hasProPlan')
        ? this.$constants.GROUP_AUTO_ADD.ALL
        : this.$constants.GROUP_AUTO_ADD.MANUAL,
      trainings: [],
      settings: {
        readonly: false,
        show_members: true,
        default_post_sort: 'PUBLISHED_DESC',
      },
    };

    if (this.group) {
      let automaticAdd;

      if (this.group.global) {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.ALL;
      } else if (this.group.trainings.length > 0) {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.TRAININGS;
      } else {
        automaticAdd = this.$constants.GROUP_AUTO_ADD.MANUAL;
      }

      return {
        newGroup: {
          ...pick(cloneDeep(this.group), Object.keys(newGroup)),
          automaticAdd,
          collection: this.group.collection?.uuid,
        },
      };
    }

    return {
      newGroup,
    };
  },
  computed: {
    ...mapState('community', ['groupIsLoading']),
    form() {
      const collection = this.collections ? {
        collection: {
          label: this.$t('attach_to_collection'),
          type: 'select',
          column: 'is-6',
          optionLabelIsKey: true,
          options: {
            [this.$t('no_collection')]: null,
            ...this.collections.reduce((prev, curr) => {
              prev[curr.name] = curr.uuid;
              return prev;
            }, {}),
          },
        },
      } : {};

      const type = this.group ? {} : {
        type: {
          label: this.$t('display_mode'),
          tooltip: this.$t('display_mode_tooltip'),
          tooltipAttrs: { multilined: true, position: 'is-left' },
          type: 'detailedselect',
          column: 'is-12',
          inputAttrs: {
            required: true,
          },
          options: [
            {
              value: 'CARD',
              label: this.$t('news_feed'),
              details: this.$t('news_feed_details'),
            },
            {
              value: 'LIST',
              label: this.$t('forum'),
              detailsHTML: this.$t('forum_details'),
            },
          ],
        },
      };

      const trainings = this.trainingsAsOptions ? {
        trainings: {
          label: this.$t('auto_add_learners'),
          selectLabel: this.$t('choose'),
          type: 'multiselect',
          column: 'is-12',
          options: this.trainingsAsOptions,
          inputAttrs: {
            floating: true,
          },
        },
      } : {};

      return {
        name: {
          label: this.$t('space_name'),
          type: 'text',
          column: 'is-6',
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        ...collection,
        state: {
          label: this.$t('status'),
          type: 'detailedselect',
          column: 'is-12',
          inputAttrs: {
            required: true,
          },
          options: [
            {
              value: 'PRIVATE',
              label: this.$t('hidden'),
              details: this.$t('hidden_details'),
            },
            {
              value: 'VISIBLE',
              label: this.$t('published'),
              detailsHTML: this.$t('published_details'),
            },
            {
              value: 'DRAFT',
              label: this.$t('draft'),
              details: this.$t('draft_details'),
            },
          ],
        },
        ...type,
        automaticAdd: {
          type: 'radio',
          column: 'is-12',
          options: [
            {
              value: this.$constants.GROUP_AUTO_ADD.ALL,
              radioLabel: this.$t('auto_add_all'),
              tooltip: this.$t('auto_add_all_tooltip'),
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
            {
              value: this.$constants.GROUP_AUTO_ADD.TRAININGS,
              radioLabel: this.$t('auto_add_some'),
              tooltip: this.$t('auto_add_some_tooltip'),
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
            {
              value: this.$constants.GROUP_AUTO_ADD.MANUAL,
              radioLabel: this.$t('manual_add'),
              tooltip: this.$t('manual_add_tooltip'),
              tooltipAttrs: { multilined: true, position: 'is-left' },
            },
          ],
        },
        ...trainings,
        'settings.default_post_sort': {
          label: this.$t('default_post_sort'),
          type: 'select',
          column: 'is-12',
          options: {
            PUBLISHED_ASC: this.$t('published_asc'),
            PUBLISHED_DESC: this.$t('published_desc'),
            LAST_COMMENTED: this.$t('last_commented'),
          },
        },
        'settings.readonly': {
          type: 'switch',
          switchLabel: this.$t('readonly_space'),
          tooltipHTML: this.$t('readonly_space_tooltip'),
          tooltipAttrs: { multilined: true, position: 'is-right' },
          column: 'is-6',
        },
        'settings.show_members': {
          switchLabel: this.$t('show_members'),
          tooltip: this.$t('show_members_tooltip'),
          tooltipAttrs: { multilined: true, position: 'is-left' },
          type: 'switch',
          column: 'is-6',
        },
        description: {
          label: this.$t('space_description'),
          type: 'editor',
          column: 'is-12',
          inputAttrs: {
            height: 160,
            placeholder: this.$t('space_description_placeholder'),
          },
          errors: [
            { name: 'maxLength', args: [5000] },
          ],
        },
      };
    },
    baseFormFields() {
      return pick(this.form, [
        'name', 'collection', 'state', 'type',
      ]);
    },
    descriptionFormFields() {
      return pick(this.form, [
        'description',
      ]);
    },
    accessFormFields() {
      return pick(this.form, [
        'automaticAdd', 'trainings',
      ]);
    },
    advancedFormFields() {
      return pick(this.form, [
        'settings.default_post_sort',
        'settings.readonly',
        'settings.show_members',
      ]);
    },
    trainingsAsOptions() {
      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        return null;
      }

      return trainings.data.reduce((prev, curr) => {
        prev[curr.uuid] = curr.name;
        return prev;
      }, {});
    },
  },
  validations: {
    newGroup: {
      description: {
        maxLength: maxLength(5000),
      },
    },
  },
  methods: {
    handle() {
      let promise;
      const data = { ...this.newGroup };
      const { GROUP_AUTO_ADD } = this.$constants;

      if (data.automaticAdd === GROUP_AUTO_ADD.ALL) {
        data.global = true;
        data.trainings = [];
      } else if (data.automaticAdd === GROUP_AUTO_ADD.TRAININGS) {
        data.global = false;
      } else {
        data.global = false;
        data.trainings = [];
      }

      delete data.automaticAdd;

      if (this.group) {
        promise = this.$store.dispatch('community/updateGroup', {
          uuid: this.group.uuid,
          data,
        });
      } else {
        promise = this.$store.dispatch('community/addGroup', data);
      }

      promise.then((data) => {
        this.$emit('done');
        this.$showMessage.success();

        if (!this.group) {
          this.$router.push({
            name: 'community_group',
            params: { uuid: data.uuid },
          });
        }
      });
    },
  },
  mounted() {
    // only in user context
    const loader = this.$buefy.loading.open({ container: this.$el });
    this.$store.dispatch('trainings/fetch')
      .finally(() => loader.close());
  },
  i18n: {
    messages: {
      fr: {
        modify_space: 'Modifier l\'espace',
        create_space: 'Créer un espace',
        about: 'À propos',
        space_access: 'Accès à l\'espace',
        advanced_settings: 'Paramètres avancés',
        cancel: 'Annuler',
        modify: 'Modifier',
        create: 'Créer',
        attach_to_collection: 'Rattacher à une collection',
        no_collection: 'Aucune collection',
        display_mode: 'Mode d\'affichage',
        display_mode_tooltip: 'Attention, le mode d\'affichage n\'est pas modifiable une fois l\'espace créé',
        news_feed: 'Fil d\'actualité',
        news_feed_details: 'Le fil d\'actualité est un mode d\'affichage par publications (texte ou multimédia) et réponses.',
        forum: 'Forum (forfait EXPERT)',
        forum_details: 'Le forum est un mode d\'affichage par thématiques, seuls les titres des sujets seront affichés et les membres accèderont aux contenus et aux réponses en cliquant sur le titre du sujet qui les intéresse.',
        auto_add_learners: 'Ajout automatique des nouveaux apprenants de la / des formation(s) suivante(s) :',
        choose: 'Choisissez...',
        space_name: 'Nom de l\'espace',
        status: 'Statut',
        hidden: 'Caché (forfait EXPERT)',
        hidden_details: 'Votre espace n\'est pas visible dans le menu, sauf pour les apprenants que vous inviterez et qui auront donc accès à son contenu.',
        published: 'Publié',
        published_details: 'Votre espace apparaît dans le menu pour tous vos apprenants. Mais seuls les apprenants que vous ajouterez à cet espace auront accès à son contenu.',
        draft: 'Brouillon',
        draft_details: 'Votre espace n\'est pas visible et personne ne peut encore y accéder. Seuls les administrateurs de l\'équipe et vous peuvent le voir et le modifier.',
        auto_add_all: 'Ajout automatique de tous les nouveaux apprenants',
        auto_add_all_tooltip: 'Chaque nouvel apprenant inscrit sur l\'une de vos formations sera automatiquement ajouté à cet espace',
        auto_add_some: 'Ajout automatique de certains nouveaux apprenants (pack EXPERT)',
        auto_add_some_tooltip: 'Chaque nouvel apprenant inscrit dans la ou les formations sélectionnée(s) sera automatiquement ajouté à cet espace',
        manual_add: 'Ajout manuel des apprenants (forfait EXPERT)',
        manual_add_tooltip: 'Il n\'y aura pas d\'ajout automatique à cet espace, vous pourrez ajouter vos apprenants manuellement',
        default_post_sort: 'Tri par défaut des publications',
        published_asc: 'Par date de publication (croissant)',
        published_desc: 'Par date de publication (décroissant)',
        last_commented: 'Par date de dernière réponse',
        readonly_space: 'Espace en lecture seule',
        readonly_space_tooltip: '(forfait EXPERT)<br>Seuls le créateur de l\'espace et les administrateurs de l\'équipe peuvent publier. Tous les membres peuvent répondre',
        show_members: 'Afficher les membres',
        show_members_tooltip: 'Seuls le créateur de l\'espace et les administrateurs de l\'équipe peuvent voir la liste des membres',
        space_description: 'Description de l\'espace',
        space_description_placeholder: 'Rédigez ici les informations importantes de cet espace : objectif, règles d\'utilisation...',
      },
      en: {
        modify_space: 'Modify space',
        create_space: 'Create a space',
        about: 'About',
        space_access: 'Space access',
        advanced_settings: 'Advanced settings',
        cancel: 'Cancel',
        modify: 'Modify',
        create: 'Create',
        attach_to_collection: 'Attach to a collection',
        no_collection: 'No collection',
        display_mode: 'Display mode',
        display_mode_tooltip: 'Attention, the display mode cannot be modified once the space is created',
        news_feed: 'News feed',
        news_feed_details: 'The news feed is a display mode for posts (text or multimedia) and replies.',
        forum: 'Forum (EXPERT plan)',
        forum_details: 'The forum is a display mode by themes, only the titles of the topics will be displayed and members will access the contents and replies by clicking on the title of the topic they are interested in.',
        auto_add_learners: 'Automatically add new learners from the following training(s):',
        choose: 'Choose...',
        space_name: 'Space name',
        status: 'Status',
        hidden: 'Hidden (EXPERT plan)',
        hidden_details: 'Your space is not visible in the menu, except for the learners you invite who will therefore have access to its content.',
        published: 'Published',
        published_details: 'Your space appears in the menu for all your learners. But only the learners you add to this space will have access to its content.',
        draft: 'Draft',
        draft_details: 'Your space is not visible and no one can access it yet. Only team administrators and you can see and modify it.',
        auto_add_all: 'Automatically add all new learners',
        auto_add_all_tooltip: 'Each new learner enrolled in one of your trainings will be automatically added to this space',
        auto_add_some: 'Automatically add some new learners (EXPERT plan)',
        auto_add_some_tooltip: 'Each new learner enrolled in the selected training(s) will be automatically added to this space',
        manual_add: 'Manually add learners (EXPERT plan)',
        manual_add_tooltip: 'There will be no automatic addition to this space, you can add your learners manually',
        default_post_sort: 'Default post sorting',
        published_asc: 'By publication date (ascending)',
        published_desc: 'By publication date (descending)',
        last_commented: 'By date of last reply',
        readonly_space: 'Read-only space',
        readonly_space_tooltip: '(EXPERT plan)<br>Only the space creator and team administrators can publish. All members can reply',
        show_members: 'Show members',
        show_members_tooltip: 'Only the space creator and team administrators can see the list of members',
        space_description: 'Space description',
        space_description_placeholder: 'Write here the important information about this space: objective, usage rules...',
      },
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    class="is-relative"
    hasCloseBtn
    @close="$emit('cancel')"
    @submit.prevent="dataIsValid(groupIsLoading) && handle()"
  >
    <template #title>
      <template v-if="group">
        {{ $t('modify_space') }}
      </template>
      <template v-else>
        {{ $t('create_space') }}
      </template>
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in baseFormFields"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newGroup"
            :formValidation="$v.newGroup"
          />
        </div>
      </div>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          {{ $t('about') }}
        </template>
        <template #default>
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in descriptionFormFields"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newGroup"
                :formValidation="$v.newGroup"
              />
            </div>
          </div>
        </template>
      </AppToggleSection>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          {{ $t('space_access') }}
        </template>
        <template #default>
          <div class="columns is-multiline">
            <template v-for="(field, k) in accessFormFields">
              <div
                v-if="
                  k !== 'trainings'
                  || newGroup.automaticAdd === 'TRAININGS'
                "
                :key="k"
                class="column mb-0"
                :class="field.column"
              >
                <GeneratorField
                  :fieldName="k"
                  :fieldDefinition="field"
                  :formData.sync="newGroup"
                  :formValidation="$v.newGroup"
                />
              </div>
            </template>
          </div>
        </template>
      </AppToggleSection>

      <AppToggleSection :disabledScroll="true">
        <template #title>
          {{ $t('advanced_settings') }}
        </template>
        <template #default>
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in advancedFormFields"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newGroup"
                :formValidation="$v.newGroup"
              />
            </div>
          </div>
        </template>
      </AppToggleSection>

      <div class="buttons is-right mt-10">
        <b-button type="is-text" @click="$emit('cancel')">
          {{ $t('cancel') }}
        </b-button>
        <b-button
          type="is-primary is-custom"
          native-type="submit"
          :loading="groupIsLoading"
        >
          <template v-if="group">
            {{ $t('modify') }}
          </template>
          <template v-else>
            {{ $t('create') }}
          </template>
        </b-button>
      </div>
    </template>
  </AppCardModal>
</template>
