import DOMPurify from 'dompurify';
import { contextRoot } from '@shared/env';

const storeStoreName = contextRoot === '@app' ? 'auth' : 'store';

export const formatPrice = (value) => {
  const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  return formatter.format(value);
};

const getSecondsFormatted = (value) => {
  const hours = Math.floor(value / (60 * 60));
  value %= (60 * 60);
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export default {
  filters: {
    formatPrice,
    formatPriceShort(value) {
      const valueString = value.toString();

      if (value > 999999999) {
        return `${valueString.slice(0, valueString.length - 9)}Md €`;
      }

      if (value > 999999) {
        return `${valueString.slice(0, valueString.length - 6)}M €`;
      }

      if (value > 999) {
        return `${valueString.slice(0, valueString.length - 3)}K €`;
      }

      return formatPrice(value);
    },
    formatSeconds(value) {
      const {
        hours,
        minutes,
        seconds,
      } = getSecondsFormatted(value);

      let result = '';

      if (hours) {
        result += hours > 9 ? hours : `0${hours}`;
        result += 'h ';
      }

      if (minutes) {
        result += minutes > 9 ? minutes : `0${minutes}`;
        result += 'm ';
      }

      result += seconds > 9 ? seconds : `0${seconds}`;
      result += 's';

      return result;
    },
    formatSecondsShort(value) {
      const {
        hours,
        minutes,
        seconds,
      } = getSecondsFormatted(value);

      let result = '';

      if (hours) {
        result += hours > 9 ? hours : `0${hours}`;
        result += 'h+';
        return result;
      }

      if (minutes) {
        result += minutes > 9 ? minutes : `0${minutes}`;
        result += 'm+';
        return result;
      }

      result += seconds > 9 ? seconds : `0${seconds}`;
      result += 's';

      return result;
    },
    formatSizeToKB(value) {
      return Math.round((value / 1024) * 100) / 100;
    },
    formatSizeToMB(value) {
      return Math.round((value / 1024 / 1024) * 100) / 100;
    },
    formatSizeToGB(value) {
      return Math.round((value / 1024 / 1024 / 1024) * 100) / 100;
    },
    toPercentage(values) {
      if (values[1] <= 0) {
        return 0;
      }

      return Math.round(((values[0] / values[1]) * 100) * 100) / 100;
    },
  },
  computed: {
    isStoreAdmin() {
      return contextRoot === '@app' && (
        this.$store.getters['auth/isRoleOwner']
        || this.$store.getters['auth/isRoleAdmin']
      );
    },
    sharedHasProPlan() {
      return this.$store.getters[`${storeStoreName}/hasProPlan`];
    },
    sharedHasPerkPlan() {
      return this.$store.getters[`${storeStoreName}/hasPerkPlan`];
    },
    sharedHasExpertPlan() {
      return this.$store.getters[`${storeStoreName}/hasExpertPlan`];
    },
    sharedHasPercentPlan() {
      return this.$store.getters[`${storeStoreName}/hasPercentPlan`];
    },
    sharedIsNotPercentPlan() {
      return this.$store.getters[`${storeStoreName}/isNotPercentPlan`];
    },
    sharedIsNotRecurringPlan() {
      return this.$store.getters[`${storeStoreName}/isNotRecurringPlan`];
    },
  },
  methods: {
    getSharedStoreGetter(name) {
      return this.$store.getters[`${storeStoreName}/${name}`];
    },
    showAsyncConfirm(
      confirmConfig,
      asyncCallback = () => Promise.resolve(),
      additionalConfig = {
        showSuccess: true,
      },
    ) {
      confirmConfig = { ...confirmConfig };
      confirmConfig.message = DOMPurify.sanitize(confirmConfig.message);

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('utils.confirm.permanent_action'),
        focusOn: 'cancel',
        confirmText: this.$t('utils.confirm.yes'),
        closeOnConfirm: false,
        onConfirm: (_, { close }) => {
          const done = () => {
            close();

            if (additionalConfig.showSuccess) {
              this.$showMessage.success();
            }
          };

          const loader = this.$buefy.loading.open();
          asyncCallback({ close, done })
            .finally(() => loader.close());
        },
        ...confirmConfig,
      });
    },
    showErrorPage(error, message = {}) {
      const status = error.response?.status;

      if (error.isAxiosError && !status) {
        this.$errorHandlers.showNetworkErrorDialog();
        return;
      }

      if (status === 404) {
        this.$store.commit('errors/setError', {
          code: 404,
          message: message[404] || this.$t('shared_messages.page404'),
        });

        return;
      }

      if (status === 403) {
        this.$store.commit('errors/setError', {
          code: 403,
          title: (
            message[403]
            || error.response.data.message
            || this.$t('shared_messages.page403')
          ),
        });

        return;
      }

      this.$errorHandlers.axios(error);
    },
    showPopupBlockedDialog(pageName, additionalText = '') {
      this.$buefy.dialog.alert(`
        <p>${this.$t('utils.confirm.popup_blocked.message', { pageName })}</p>
        <p class="mt-2">${this.$t('utils.confirm.popup_blocked.instruction')}</p>
        ${additionalText}
      `);
    },
    hydrateData(modelData, inputData) {
      if (this[inputData]) {
        Object.keys(this[modelData])
          .forEach((k) => (this[modelData][k] = this[inputData][k]));
      }
    },
  },
};
