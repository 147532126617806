import { api } from '@shared/config/axios';

export default {
  update(storeUUID, data) {
    return api.$put(`/stores/${storeUUID}`, data);
  },
  updateSEO(storeUUID, data) {
    return api.$put(`/stores/${storeUUID}/seo`, data);
  },
  getOnboarding(storeUUID) {
    return api.$get(`/stores/${storeUUID}/onboarding`);
  },
  removeTag(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/crm/tags`, {
      ...data,
      _method: 'DELETE',
    });
  },
  checkSubdomain(subdomain) {
    return api.$get(`/stores/slugs/availabilities?slug=${subdomain}`);
  },
  getCompany(storeUUID) {
    return api.$get(`/stores/${storeUUID}/companies`);
  },
  getSocialLinks(storeUUID) {
    return api.$get(`/stores/${storeUUID}/social_links`);
  },
  addSocialLink(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/social_links`, data);
  },
  deleteSocialLink(storeUUID, socialLinkId) {
    return api.$delete(`/stores/${storeUUID}/social_links/${socialLinkId}`);
  },
  getResources(storeUUID) {
    return api.$get(`/stores/${storeUUID}/resources`);
  },
  addResource(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/resources`, data);
  },
  deleteResource(storeUUID, resourceId) {
    return api.$delete(`/stores/${storeUUID}/resources/${resourceId}`);
  },
  reorderResources(storeUUID, data) {
    return api.$put(`/stores/${storeUUID}/resources/reordering`, data);
  },
  updateCompany(storeUUID, companyId, data) {
    return api.$put(`/stores/${storeUUID}/companies/${companyId}`, data);
  },
  getStats(storeUUID, { begin, end }) {
    return api.$get(`/stores/${storeUUID}/stats?begin=${begin}&end=${end}`);
  },
  getHistogramStats(storeUUID, { tz, begin, end }) {
    return api.$get(`/stores/${storeUUID}/stats/histogram`, { params: { tz, begin, end } });
  },
  getInvoices(storeUUID) {
    return api.$get(`/stores/${storeUUID}/invoices`);
  },
  getInvoice(storeUUID, invoiceId) {
    return api.$get(`/stores/${storeUUID}/invoices/${invoiceId}`);
  },
  addDiscount(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/discount_codes`, data);
  },
  getDiscounts(storeUUID, page) {
    return api.$get(`/stores/${storeUUID}/discount_codes`, { params: { page } });
  },
  deleteDiscount(storeUUID, discountId) {
    return api.$delete(`/stores/${storeUUID}/discount_codes/${discountId}`);
  },
  getEmailTemplates(storeUUID) {
    return api.$get(`/stores/${storeUUID}/email_templates`);
  },
  updateEmailTemplate(storeUUID, templateId, data) {
    return api.$put(`/stores/${storeUUID}/email_templates/${templateId}`, data);
  },
  getUrlInfos(storeUUID, url) {
    return api.$get(`/stores/${storeUUID}/urls`, { params: { url } });
  },
  getOEmbed(storeUUID, url) {
    return api.$get(`/stores/${storeUUID}/oembed`, { params: { url } });
  },
  getCustomers(urlORstoreUUID, params) {
    if (urlORstoreUUID.startsWith('http')) {
      return api.$get(urlORstoreUUID);
    }

    return api.$get(`/stores/${urlORstoreUUID}/customers`, { params });
  },
  addCustomer(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/customers`, data);
  },
  updateCustomer(storeUUID, customerUUID, data) {
    return api.$put(`/stores/${storeUUID}/customers/${customerUUID}`, data);
  },
  addCustomerTrainings(storeUUID, customerUUID, data) {
    return api.$post(`/stores/${storeUUID}/customers/${customerUUID}/trainings`, data);
  },
  exportCustomers(storeUUID) {
    return api.$get(`/stores/${storeUUID}/customers/export`);
  },
  exportCustomerLogs(storeUUID, customerUUID, trainingUUID) {
    return api.$get(`/stores/${storeUUID}/customers/${customerUUID}/logs/export`, {
      params: {
        training_uuid: trainingUUID,
      },
    });
  },
  exportAbandonedCart(storeUUID) {
    return api.$get(`/stores/${storeUUID}/abandoned_cart/export`);
  },
  getCustomer(storeUUID, customerUUID) {
    return api.$get(`/stores/${storeUUID}/customers/${customerUUID}`);
  },
  getCustomerConnections(urlORstoreUUID, customerUUID, params) {
    if (urlORstoreUUID.startsWith('http')) {
      return api.$get(urlORstoreUUID);
    }

    return api.$get(
      `/stores/${urlORstoreUUID}/customers/${customerUUID}/connections`,
      { params },
    );
  },
  addCustomerTag(storeUUID, customerUUID, data) {
    return api.$put(`/stores/${storeUUID}/customers/${customerUUID}/crm/tags`, data);
  },
  removeCustomerTag(storeUUID, customerUUID, data) {
    return api.$post(`/stores/${storeUUID}/customers/${customerUUID}/crm/tags`, {
      ...data,
      _method: 'DELETE',
    });
  },
  addCustomerNote(storeUUID, customerUUID, data) {
    return api.$put(`/stores/${storeUUID}/customers/${customerUUID}/crm/notes`, data);
  },
  removeCustomerNote(storeUUID, customerUUID, noteId) {
    return api.$delete(`/stores/${storeUUID}/customers/${customerUUID}/crm/notes/${noteId}`);
  },
  addOrUpdateCustomerPrivateData(storeUUID, customerUUID, data) {
    return api.$put(`/stores/${storeUUID}/customers/${customerUUID}/crm/private_data`, data);
  },
  getCustomerTraining(storeUUID, customerUUID, trainingUUID) {
    return api.$get(`/stores/${storeUUID}/customers/${customerUUID}/trainings/${trainingUUID}`);
  },
  unblockCustomerTrainingItem(storeUUID, customerUUID, trainingUUID, trainingItemUUID) {
    return api.$put(`
      /stores/${storeUUID}/customers/${customerUUID}/trainings/${trainingUUID}/training_items/${trainingItemUUID}/unblock
    `.trim());
  },
  getSales(urlORstoreUUID, params) {
    if (urlORstoreUUID.startsWith('http')) {
      return api.$get(urlORstoreUUID);
    }

    return api.$get(`/stores/${urlORstoreUUID}/sales`, { params });
  },
  exportSales(storeUUID) {
    return api.$get(`/stores/${storeUUID}/sales/export`);
  },
  getPreviewToken(storeUUID) {
    return api.$post(`/stores/${storeUUID}/token`);
  },
  getEmailMarketingProviders(storeUUID) {
    return api.$get(`/stores/${storeUUID}/emails/providers`);
  },
  updateEmailMarketingProviders(storeUUID, data) {
    return api.$put(`/stores/${storeUUID}/emails/providers`, data);
  },
  testWebhook(storeUUID, { url, webhook }) {
    return api.$post(`/stores/${storeUUID}/tools/webhooks`, { url, webhook });
  },
  generateKey(storeUUID, type) {
    return api.$post(`/stores/${storeUUID}/keys`, { type });
  },
  deleteKey(storeUUID, { password, type }) {
    const data = {
      _method: 'DELETE',
      password,
      type,
    };

    return api.$post(`/stores/${storeUUID}/keys`, data);
  },
  generateAIContent(storeUUID, data) {
    return api.$post(`/stores/${storeUUID}/ai_contents`, data);
  },
  getAIContents(storeUUID, params) {
    return api.$get(`/stores/${storeUUID}/ai_contents`, { params });
  },
  getTags(storeUUID, params) {
    return api.$get(`/stores/${storeUUID}/tags`, { params });
  },
  updateTag(storeUUID, tag, value) {
    return api.$put(`/stores/${storeUUID}/tags/${tag}`, { value });
  },
  deleteTag(storeUUID, tag) {
    return api.$delete(`/stores/${storeUUID}/tags/${tag}`);
  },
};
