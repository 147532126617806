<script>
import responsiveMixin from '@shared/mixins/responsive';
import Offline from '@shared/components/Offline.vue';
import godfatherMixin from '@app/mixins/godfather';
import Page404 from '@app/pages/404.vue';
import ResponsiveNoAccessMessage from '@app/components/ResponsiveNoAccessMessage.vue';
import OnboardingQuestions from '@app/components/OnboardingQuestions.vue';

export default {
  name: 'App',
  mixins: [godfatherMixin, responsiveMixin({ isMobileScreen: 768 })],
  components: {
    Offline,
    Page404,
    ResponsiveNoAccessMessage,
    OnboardingQuestions,
  },
  head: {
    title: 'Teachizy+',
    titleTemplate: '%s | Teachizy+',
  },
  data() {
    return {
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    canShowStoreOnboarding() {
      return (
        this.authUser
        && this.$store.getters['auth/isRoleOwner']
        && !this.$route.meta.noOnboarding
        && (
          (!!this.$route.query.questionnaire)
          || !this.authStore.onboarding_questions
        )
      );
    },
  },
};
</script>

<template>
  <div class="layout">
    <StagingBanner />
    <MFLayout v-if="$store.state.auth.ready">
      <template v-if="$store.state.errors.code">
        <Page404 />
      </template>
      <template v-else>
        <router-view />
      </template>
      <b-modal
        v-if="authUser"
        :active="canShowStoreOnboarding"
        :can-cancel="false"
        :full-screen="isMobileScreen"
        has-modal-card>
        <OnboardingQuestions :isMobileScreen="isMobileScreen" />
      </b-modal>
    </MFLayout>
    <b-loading v-else class="has-background-light" active />
    <teleport-target name="modals" multiple />
    <ResponsiveNoAccessMessage v-if="$store.state.auth.ready" />
    <Offline />
  </div>
</template>

<style lang="scss" scoped>
.mobile-message {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $theme_color_black;
}
</style>
