<script>
export default {
  props: {
    hasSep: {
      type: Boolean,
      default: true,
    },
    bg: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    planLock: {
      type: String,
      default: null,
    },
  },
  data: () => ({ hasBackdropCss: false }),
  computed: {
    currentPlan() {
      return this.$store.getters['auth/store'].plan;
    },
    canSee() {
      if (this.planLock === 'EXPERT') {
        return this.$store.getters['auth/hasExpertPlan'];
      }

      if (this.planLock === 'PRO') {
        return (
          this.$store.getters['auth/hasProPlan']
          || this.$store.getters['auth/hasExpertPlan']
        );
      }

      if (this.planLock === 'PERK') {
        return (
          this.$store.getters['auth/hasPerkPlan']
          || this.$store.getters['auth/hasProPlan']
          || this.$store.getters['auth/hasExpertPlan']
        );
      }

      return this.$store.getters['auth/isNotPercentPlan'];
    },
  },
  mounted() {
    this.hasBackdropCss = ('backdropFilter' in this.$el.style);
  },
};
</script>

<template>
  <div class="gpwcomp">
    <template v-if="canSee">
      <slot />
    </template>
    <template v-else>
      <hr v-show="hasSep" />

      <div
        class="gpwcomp_box bradius-4 has-text-centered overflow-hidden"
        :style="{
          height,
          background: bg && `url(${bg}) top center`,
          backgroundSize: bg && 'contain',
        }">
        <div
          class="flex-center-center-column p-5 w-full"
          :style="{
            height,
            background: !hasBackdropCss && bg ? 'rgba(255,255,255,0.9)' : null,
            backdropFilter: hasBackdropCss && bg ? 'blur(5px)' : null,
          }">
          <p>
            Votre forfait actuel (<span v-t="`packs.${currentPlan}`" />)
            <br>
            ne vous permet pas d'avoir accès à cette fonctionnalité.
          </p>
          <p v-if="planLock" class="mt-3">
            Seuls ceux ayant un
            <strong>
              <span v-t="`packs.${planLock}`" /> ou supérieur
            </strong>
            <br>
            peuvent bénéficier de cette fonctionnalité.
          </p>
          <p class="mt-5">
            <b-button
              tag="router-link"
              type="is-warning"
              icon-right="gem"
              :to="{name: 'plan', query: {onglet: 'vous'}}"
              @click.native="$emit('cta-click')"
            >
              Activer cette fonctionnalité !
            </b-button>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scope>
.gpwcomp {
  &_box {
    border: 2px solid $warning;
    background: rgba($warning, 0.2);
  }
}
</style>
