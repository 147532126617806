var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gpwcomp"},[(_vm.canSee)?[_vm._t("default")]:[_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSep),expression:"hasSep"}]}),_c('div',{staticClass:"gpwcomp_box bradius-4 has-text-centered overflow-hidden",style:({
        height: _vm.height,
        background: _vm.bg && ("url(" + _vm.bg + ") top center"),
        backgroundSize: _vm.bg && 'contain',
      })},[_c('div',{staticClass:"flex-center-center-column p-5 w-full",style:({
          height: _vm.height,
          background: !_vm.hasBackdropCss && _vm.bg ? 'rgba(255,255,255,0.9)' : null,
          backdropFilter: _vm.hasBackdropCss && _vm.bg ? 'blur(5px)' : null,
        })},[_c('p',[_vm._v(" Votre forfait actuel ("),_c('span',{directives:[{name:"t",rawName:"v-t",value:(("packs." + _vm.currentPlan)),expression:"`packs.${currentPlan}`"}]}),_vm._v(") "),_c('br'),_vm._v(" ne vous permet pas d'avoir accès à cette fonctionnalité. ")]),(_vm.planLock)?_c('p',{staticClass:"mt-3"},[_vm._v(" Seuls ceux ayant un "),_c('strong',[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("packs." + _vm.planLock)),expression:"`packs.${planLock}`"}]}),_vm._v(" ou supérieur ")]),_c('br'),_vm._v(" peuvent bénéficier de cette fonctionnalité. ")]):_vm._e(),_c('p',{staticClass:"mt-5"},[_c('b-button',{attrs:{"tag":"router-link","type":"is-warning","icon-right":"gem","to":{name: 'plan', query: {onglet: 'vous'}}},nativeOn:{"click":function($event){return _vm.$emit('cta-click')}}},[_vm._v(" Activer cette fonctionnalité ! ")])],1)])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }